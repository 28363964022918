import React, { Component } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Sticky from "react-stickynode"
import FeaturedPublications from "../components/featuredpublications.js"
import { isMobile, cdnify } from "../components/utilities.js"
import NewsItem from "../components/newsitem.js"
import { ParallaxProvider, ParallaxBanner } from "react-scroll-parallax"

class IndexPageTemplate extends Component {
  constructor(props) {
    super(props)
    this.data = this.props.data
    this.newsCount = this.props.data.wordpressPage.acf.news_count
    this.bookCount = this.props.data.wordpressPage.acf.book_count
    const media_details = this.props.data.wordpressPage.acf.home_image
    const sizes = this.props.data.wordpressPage.acf.home_image.media_details
      .sizes
    const sized = sizes.large || media_details
    
    this.homeImage = cdnify(sized.source_url)
    //cdnify(this.props.data.wordpressPage.acf.home_image.media_details.sizes.large.source_url)
    this.state = { width: 0, height: 0 }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Gregory R. Miller &amp; Company</title>
          <link rel="canonical" href={process.env.SITE_URL} />
        </Helmet>

        <ParallaxProvider>
          <div className="intro">
            <div className="big_photo">
              <ParallaxBanner
                layers={[
                  {
                    image: this.homeImage,
                    amount: 0.2,
                  },
                ]}
                style={{
                  height: "100%",
                }}
              ></ParallaxBanner>
            </div>
          </div>

          <div className="aux_content news">
            <div className="pusher">
              <Sticky
                top={130}
                bottomBoundary=".main_content"
                enabled={isMobile(this.state.width)}
              >
                <div className="pushed">
                  <div class="news_home">
                    {this.data.allWordpressPost.edges
                      .slice(0, this.newsCount)
                      .map(({ node }) => (
                        <NewsItem
                          node={node}
                          key={node.id}
                          showImage={true}
                        ></NewsItem>
                      ))}
                  </div>
                </div>
              </Sticky>
            </div>
          </div>

          <div className="main_content">
            <FeaturedPublications
              titles={this.data.allWordpressWpTitles.edges.slice(
                0,
                this.bookCount
              )}
            ></FeaturedPublications>
          </div>
        </ParallaxProvider>
      </div>
    )
  }
}

export default IndexPageTemplate

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "home-text" }) {
      content
      acf {
        news_count
        book_count
        home_image {
          id
          source_url
          media_details {
            width
            height
            file
            sizes {
              medium {
                file
                width
                height
                mime_type
                source_url
              }
              medium_large {
                file
                width
                height
                mime_type
                source_url
              }
              large {
                file
                width
                height
                mime_type
                source_url
              }
            }
          }
        }
      }
    }
    allWordpressWpTitles(
      sort: { order: DESC, fields: acf___publish_date }
      limit: 10
    ) {
      edges {
        node {
          id
          slug
          title
          content
          acf {
            publish_date
            isbn
            dap_link
            isbn
            dap_link
            publish_date
            dimensions {
              width
              height
            }
            cover_image {
              id
              source_url
              media_details {
                width
                height
                file
                sizes {
                  tiny {
                    file
                    width
                    height
                    mime_type
                    source_url
                  }
                  thumbnail {
                    file
                    width
                    height
                    mime_type
                    source_url
                  }
                  medium {
                    width
                    height
                    source_url
                  }
                  large {
                    width
                    height
                    source_url
                  }
                  full {
                    width
                    height
                    source_url
                  }
                }
              }
            }
          }
          people
          slug
        }
      }
    }
    allWordpressPost(sort: { fields: date, order: DESC }, limit: 10) {
      edges {
        node {
          id
          title
          content
          slug
          date(formatString: "MMMM DD, YYYY")
          acf {
            news_image {
              id
              source_url
              media_details {
                width
                height
                file
                sizes {
                  tiny {
                    file
                    width
                    height
                    mime_type
                    source_url
                  }
                  thumbnail {
                    file
                    width
                    height
                    mime_type
                    source_url
                  }
                  medium {
                    width
                    height
                    source_url
                  }
                  large {
                    width
                    height
                    source_url
                  }
                  full {
                    width
                    height
                    source_url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
